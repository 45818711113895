export default {
  data: () => ({
    tag: {
      id: 0,
      name: "",
      company_id:'',
    },
    loading: false,
    companyList:[],
    backUrl: "/tag",
  }),
  methods: {
    resetForm() {
      this.$refs.observer.reset();
      this.tag = {
        id: 0,
        name: "",
      };
    },
    validateTag() {
      this.tag.id > 0 ? this.update() : this.add();
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 10000);
    },
    add() {
      let _vm = this;
      this.axios
        .post("/blog/tags", _vm.tag)
        .then(function () {
          _vm.$router.push(_vm.backUrl);
        })
        .catch(function () {});
    },
    getDetail() {
      let _vm = this;
      this.axios
        .get("/blog/tags/" + this.$route.params.id)
        .then(function (response) {
          _vm.tag = response.data.data;
        })
        .catch(function () { });
    },
    getCompany() {
      let _vm = this;
      this.axios
        .get("company-list")
        .then(function (response) {
          _vm.companyList = response.data.data;
          _vm.tag.company_id = _vm.tag.company_id ? _vm.tag.company_id : _vm.companyList[0].id;
        })
        .catch(function () { });
    },
  },
  mounted() {
    if (this.$route.params.id !== undefined && this.$route.params.id !== "") {
      this.getDetail();
    }
    this.getCompany();
  },
};
